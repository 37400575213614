/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        jQuery(document).foundation(); // Foundation JavaScript

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      jQuery.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.





jQuery('.description-item').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  asNavFor: '.name-item, .image-item',
  centerMode: true,
  centerPadding: '160px', 
  focusOnSelect: true,
  responsive: [
  {
    breakpoint: 900,
    settings: {
      centerPadding: '40px',
    }
  },
  {
    breakpoint: 640,
    settings: {
      centerPadding: '0px',
    }
  }
  ]
});
jQuery('.image-item').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  infinite: false,
  asNavFor: '.name-item, .description-item'   
});


jQuery('.name-item').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.description-item, .image-item', 
  centerMode: false,
  focusOnSelect: true,
  responsive: [
  {
    breakpoint: 640,
    settings: {
      slidesToShow: 1,
    }
  }
  ]
});

// ------------------------------------------

jQuery('.advantages-items').slick({
  slidesToShow: 5, 
  slidesToScroll: 1,
  asNavFor: '.advantages-description',
  dots: false,
  arrows: true,  
  centerMode: false,
  focusOnSelect: true,
  responsive: [
  {
    breakpoint: 900,
    settings: {
      slidesToShow: 4,
    }
  },
  {
    breakpoint: 700,
    settings: {
      slidesToShow: 3,
    }
  },
  {
    breakpoint: 550,
    settings: {
      slidesToShow: 2,
    }
  },
  {
    breakpoint: 350,
    settings: {
      slidesToShow: 1,
    }
  }
  ]
}); 
jQuery('.advantages-description').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false, 
  fade: true,
  asNavFor: '.advantages-items'
});


// -------------------------------------------

jQuery('.portfolio').slick({
  slidesToShow: 2,
  slidesToScroll: 1,
  dots: false, 
  arrows: true, 
    // centerMode: true, 
    // centerPadding: '160px',
    focusOnSelect: true,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        arrows: false, 
        centerMode: true,
        centerPadding: '40px',
      }
    },
    {
      breakpoint: 640,
      settings: { 
        arrows: false, 
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '15px', 
      }
    }
    ]
  });     


// ================= project hover opacity =====================
var timer;

jQuery('.project').hover(function() {

  var thiss = jQuery(this);
  timer = setTimeout(function() {
    thiss.find('h3, p').addClass('active');
  }, 300);
  timer = setTimeout(function() {
    thiss.find('span').addClass('active');
  }, 700);
}, function() {
 jQuery('.project h3, .project span, .project p').removeClass('active');
 clearTimeout(timer); 
}); 

/*------------------------------------*\
        fonts
        \*------------------------------------*/
        WebFontConfig = {
          google: { families: [ 'Open+Sans:400,300:latin,cyrillic', 'Josefin+Sans:400,300:latin', 'Roboto:400,300:latin,cyrillic' ] }
        };
        (function() {
          var wf = document.createElement('script');
          wf.src = ('https:' === document.location.protocol ? 'https' : 'http') +
          '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';
          wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

  // WebFontConfig = {
  //   google: { families: [ 'Poiret+One::latin,cyrillic' ] }
  // };
  // (function() {
  //   var wf = document.createElement('script');
  //   wf.src = ('https:' === document.location.protocol ? 'https' : 'http') +
  //     '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  //   wf.type = 'text/javascript';
  //   wf.async = 'true';
  //   var s = document.getElementsByTagName('script')[0];
  //   s.parentNode.insertBefore(wf, s);
  // })();

  // WebFontConfig = {
  //   google: { families: [ 'Open+Sans:400,300:latin,cyrillic' ] }
  // };
  // (function() {
  //   var wf = document.createElement('script');
  //   wf.src = ('https:' === document.location.protocol ? 'https' : 'http') +
  //     '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  //   wf.type = 'text/javascript';
  //   wf.async = 'true';
  //   var s = document.getElementsByTagName('script')[0];
  //   s.parentNode.insertBefore(wf, s);  
  // })(); 


  // WebFontConfig = {
  //   google: { families: [ 'Josefin+Sans:400,300:latin' ] }
  // };
  // (function() {
  //   var wf = document.createElement('script');
  //   wf.src = ('https:' === document.location.protocol ? 'https' : 'http') +
  //     '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  //   wf.type = 'text/javascript';
  //   wf.async = 'true';
  //   var s = document.getElementsByTagName('script')[0];
  //   s.parentNode.insertBefore(wf, s);
  // })();


// var headerHeight = $('header').offset().top;
// $(window).scroll(function(){

//   var scroll = $(document).scrollTop();
//   if(headerHeight < scroll){
//     $('header').addClass('fixed');
//     // $('.lining').css('height', '100vh');
//     $('.lining-header').show();
//     $('.intro-header').hide();
//   }
//   // else{
//   //   $('header').removeClass('fixed'); 
//   //   $('.lining').css('height', ''); 
//   //   $('.lining-header').css('display', 'none');
//   // }

//   if(scroll !== 0 ){
//     $('header').css({'background': 'rgba(255, 255, 255, 0.3)', 'transition': '0.4s'});
//   }
//   // else{
//   //   $('header').css('background', '');
//   // } 
// });


/*----------------------------
    play/pause video in view
    ----------------------------*/ 
// var videos = document.getElementsByTagName("video"),
// fraction = 0.8;
// function checkScroll() {

//     for(var i = 0; i < videos.length; i++) {

//         var video = videos[i];

//         var x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
//             b = y + h, //bottom
//             visibleX, visibleY, visible;

//             visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
//             visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

//             visible = visibleX * visibleY / (w * h);

//             if (visible > fraction) {
//                 video.play(); 
//             } else {
//                 video.pause();
//             }

//     }

// } 

// window.addEventListener('scroll', checkScroll, false);
// window.addEventListener('resize', checkScroll, false); 


jQuery(document).ready(function() {

  function checkMedia(){
          // Get current browser top and bottom

          var scrollTop = jQuery(window).scrollTop() + tolerancePixel;
          var scrollBottom = jQuery(window).scrollTop() + jQuery(window).height() - tolerancePixel;

          //if ($(window).scrollTop() > $(window).height() - 10) {
            media.each(function(index, el) {
              var yTopMedia = jQuery(this).offset().top;
              var yBottomMedia = jQuery(this).height() + yTopMedia;

              if(scrollTop < yBottomMedia && scrollBottom > yTopMedia){
                jQuery(this).get(0).play();
              } else {
                jQuery(this).get(0).pause(); 
              }
            });

          //}
        }

        if(jQuery(document).width()>1024){
            // Get media - with autoplay disabled (audio or video)
            var media = jQuery('video').not("[autoplay='autoplay']");
            var tolerancePixel = 100;


            jQuery(document).on('scroll', checkMedia);
          }

        });
// =================== 404 =======================

// var arrayGif = [
//   '../dist/images/404-gif/giphy.gif',
//   '../dist/images/404-gif/gif1.gif',
//   '../dist/images/404-gif/gif2.gif',
//   '../dist/images/404-gif/4eN.gif',
// ];
// var random = Math.floor((Math.random() * 4)+1);
// jQuery('#gif-bg').attr('style', 'background-image: url('+ arrayGif[random] + ');'); 
// ====================== wow init ===================================

new WOW().init(); 

// --------------  scroll arrow ---------------------

var introHeight = jQuery('#intro').height() - 59; 

jQuery('.arrow').click(function(){
 jQuery('html,body').animate({scrollTop: introHeight}, 1500, 'swing');
});  

// ============================== footer ===================================

if(jQuery(document).width()>640){
  var containerHeight = jQuery(document).innerHeight() - ( jQuery(window).height() + 700 );

  jQuery(document).scroll(function(){
    var scroll = jQuery(document).scrollTop();

    if(containerHeight<scroll){ 
      jQuery("footer").show();
    }else{
      jQuery('footer').hide();
    } 
  });

}

// ================================  header ============================================

var headerHeight = jQuery('header').offset().top;

if(jQuery('header').hasClass("front-page")){

  jQuery(window).scroll(function(){

    var scroll = jQuery(document).scrollTop();
    if(headerHeight < scroll){
      jQuery('header').addClass('fixed');
      // $('.lining').css('height', '100vh');
      jQuery('.lining-header-front').show();
      jQuery('.intro-header').css("opacity", 0);
    }
    // else{
    //   $('header').removeClass('fixed'); 
    //   $('.lining').css('height', ''); 
    //   $('.lining-header').css('display', 'none');
    // }

    if(scroll !== 0 ){
      jQuery('header').css({'background': 'rgba(255, 255, 255, 0.3)', 'transition': '0.4s'});
    }
    // else{
    //   $('header').css('background', '');
    // } 
  });
}


if(jQuery('body').hasClass("single-project")){
  jQuery('header').addClass('project-header');
  jQuery('header .name img').attr('src', "http://lionline.agency/wp-content/themes/lionline_new/dist/images/logo_white.svg");
  if(jQuery(document).width()>1024){
    jQuery(".lining-header").hide(); 
  } 
}



// ====================== intro animation ===================================
  /**
   * Scroller
   */
   function Scroller(){
    this.latestKnownScrollY = 0;
    this.ticking            = false;
  }
  
if(jQuery(document).width()>640){
  /**
   * Cache
   */
   var $content = jQuery('#intro h1, .arrow'),
   $blur    = jQuery('.overlay'),
   wHeight  = jQuery(window).height();

   jQuery(window).on('resize', function(){
    wHeight = jQuery(window).height();
  });

  /**
   * requestAnimationFrame Shim 
   */
   window.requestAnimFrame = (function()
   {
    return  window.requestAnimationFrame       ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame    ||
    function( callback ){
      window.setTimeout(callback, 1000 / 60);
    };
  })();



  Scroller.prototype = {
    /**
     * Initialize
     */
     init: function() {
      window.addEventListener('scroll', this.onScroll.bind(this), false);
    },

    /**
     * Capture Scroll
     */
     onScroll: function() {
      this.latestKnownScrollY = window.scrollY;
      this.requestTick();
    },

    /**
     * Request a Tick
     */
     requestTick: function() {
      if( !this.ticking ) {
        window.requestAnimFrame(this.update.bind(this));
      }
      this.ticking = true;
    },

    /**
     * Update.
     */
     update: function() {
      var currentScrollY = this.latestKnownScrollY;
      this.ticking       = false;
      
      /**
       * Do The Dirty Work Here
       */
       var slowScroll = currentScrollY / 4,
       blurScroll = currentScrollY * 2;

       $content.css({
        'transform'         : 'translateY(' + slowScroll + 'px)',
        '-moz-transform'    : 'translateY(' + slowScroll + 'px)',
        '-webkit-transform' : 'translateY(' + slowScroll + 'px)',
        'opacity': 1 - (slowScroll / 50),  
      });

       $blur.css({
        'opacity' : blurScroll / wHeight
        // '-webkit-filter': 'blur(' + blurScroll / 50 + 'px)'
      });
     }
   };

  /**
   * Attach!
   */
   var scroller = new Scroller();  
   scroller.init();
 }

// ====================== portfolio filter ================


// //init Isotope
    // var $grid = $('.grid').isotope({
    //  masonry: {
    //     columnWidth: 0
    //   }
    // });


    // // filter items on button click
    // $('.filter-btn').on( 'click', 'a', function() {
    //   var filterValue = $(this).attr('data-filter');
    //   $('.grid').isotope({ filter: filterValue });
    // });

    // // footer show
    // containerHeightChenge = function(){
    //  containerHeight = $(document).innerHeight() - ( $(window).height() + 500 );
    // };

    // $('.grid').isotope( 'on', 'layoutComplete', containerHeightChenge );

    jQuery('.filter-btn').on( 'click', 'a', function() {
      var filterValue = jQuery(this).attr('data-filter');
      jQuery('.grid .element-item').removeClass("disabled");
      jQuery('.grid .element-item').not(filterValue).addClass("disabled");
    });



/*------------------------------------*\
        vivus.js svg animation
        \*------------------------------------*/
        jQuery(document).ready(function(){
          new Vivus('error', {duration: 200, type: 'delayed', start: 'inViewport'});
        });

        var development = new Vivus('development', {type: 'delayed', start: 'inViewport'});
        var support = new Vivus('support', {type: 'delayed', start: 'inViewport'});
        var design = new Vivus('design', {type: 'delayed', start: 'inViewport'});

// $('.service').hover(function(){
//  var thiss = $(this).find('svg').attr('id');
//  thiss.play(1);
// });

jQuery('.service.development').on('mouseover', function(){
  development.stop().reset(1).play(2);
});
jQuery('.service.design')
.on('mouseover', function(){
  design.stop().reset(1).play(2); 
});
jQuery('.service.support')
.on('mouseover', function(){  
  support.stop().reset(1).play(2);
});



// ====================== smooth scroll anchor ================

jQuery(function() {
  jQuery('a[href*=#]:not([href=#])').click(function() {
    if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
      var target = jQuery(this.hash);
      target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        jQuery('html,body').animate({
          scrollTop: target.offset().top - 60
        }, 1000);
        return false;
      }
    }
  });
});


